
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import config from '@/config';
export default {
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapGetters('user', ['isAuthenticated', 'getLocale', 'getToken'])
  },
  created() {
    var flowChat = localStorage.getItem('flowgo');
    var isprod = localStorage.getItem('chatIsProd');
    renderFlowSettings('flow-settings', {
      lang: this.getLocale, //'en',
      isSandbox: isprod, //true,
      token: this.getToken
    });
  }
};
